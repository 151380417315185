import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/css/interoperable-css",
  "date": "2015-12-24",
  "title": "INTEROPERABLE CSS",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "Interoperable CSS or ICSS is a low-level file format that enables CSS Modules, designed for loader implementers (not for end users)."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why Interoperable CSS?`}</h2>
    <p>{`Even though JavaScript has evolved into building workflows to produce reusable components with scoped modules, CSS has remained conventional.`}</p>
    <Message type="info" title="ICSS is for compilers, not for human authoring" content="ICSS is compiled fom CSS Modules. Each file is compiled separately then linked in the loader." mdxType="Message" />
    <p>{`JavaScipt used to be global before the introduction of `}<strong parentName="p">{`CommonJS`}</strong>{`. With `}<inlineCode parentName="p">{`require`}</inlineCode>{` and `}<inlineCode parentName="p">{`module.exports`}</inlineCode>{`, a proper system of dependenices and proper local scoping was introduced. ICSS is designed to achive the same goal.`}</p>
    <h2>{`:export`}</h2>
    <p>{`Exporting symbols from a file to another CSS or JS file is done by using the `}<inlineCode parentName="p">{`:export`}</inlineCode>{` pseudoselector block.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`:export {
  Page: _page_page_afd97dfs867;
  Header: _page_header_85fd867fsfg;
  Footer: _page_footer_97fd867fsfg;
}
._page_page_afd85dfs867 { /* page styles */ }
._page_header_85fd867fsfg { /* header styles */ }
._page_footer_97fd867fsfg { /* footer styles */ }
`}</code></pre>
    <p>{`The symbols are imported as a simple JS object`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import styles from './page.css';
/*
 styles: {
   Page: "_page_page_afd85dfs867",
   Header: "_page_header_85fd867fsfg",
   Footer: "_page_footer_97fd867fsfg"
 }
 */
`}</code></pre>
    <p>{`CSS Modules generate globally unique class names using `}<inlineCode parentName="p">{`:export`}</inlineCode>{` and provide them to JS.`}</p>
    <h2>{`:import`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`:import`}</inlineCode>{` pseudoselector just like CommonJS import, defines which symbols it needs to import.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`:import("./page.css") {
  i__page_class_1: Header;
  i__page_class_2: Footer;
  I__page_var_1: SharedPageVar;
}
`}</code></pre>
    <p>{`The loader will determine the path looking at the argument given to it, fetches the file and links it to the current.`}</p>
    <p>{`In the body of the import statement, is the `}<strong parentName="p">{`local temporary aliases`}</strong>{` and the exported symbols from the dependency, which matches up to the `}<inlineCode parentName="p">{`:export`}</inlineCode>{` block of `}<inlineCode parentName="p">{`page.css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`:export {
  Header: _page_header_85fd867fsfg;
  Footer: _page_footer_97fd867fsfg;
  SharedPageVar: #FFFFFF;
}
`}</code></pre>
    <p>{`In the above example, we are exporting class name as `}<inlineCode parentName="p">{`Header`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Footer`}</inlineCode>{`.`}</p>
    <p>{`When an ICSS file is loaded and linked, the symbols gets passed with the `}<inlineCode parentName="p">{`:import`}</inlineCode>{` block is deleted.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`:import("./page.css") {
  i__page_class_1: Header;
  i__page_class_2: Footer;
  i__page_var_1: SharedPageVar;
}

:export {
  Page: _page_page_afd85dfs867;
}

_page_page_afd85dfs867 {
  color: i__page_var_1;
}
`}</code></pre>
    <p>{`After the `}<inlineCode parentName="p">{`:import`}</inlineCode>{` is processed against the `}<inlineCode parentName="p">{`:export`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`:export {
  Page: _page_page_afd85dfs867;
}

_page_page_afd85dfs867 {
  color: i__page_var_1;
}
`}</code></pre>
    <p>{`When this processed file is imported, it becomes a JavaScript object, and the loader will inject it inot the DOM.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The difference between `}<strong parentName="p">{`CSS Modules`}</strong>{` and `}<strong parentName="p">{`ICSS`}</strong>{` is that the latter is not opiniated. There is an `}<strong parentName="p">{`Interoperable CSS Standard`}</strong>{` which is designed towards making CSS a multi-file language, just like what CommonJS did with JavaScript.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      